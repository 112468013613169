<template>
  <div class="popup" ref="popup">
    <div class="popup__container">
      <div class="popup__header">
        <img src="@/assets/images/icons/warning.svg" alt="Warning-Icon" />
        <span>
          {{ header }}
        </span>
        <img
          src="@/assets/images/icons/cancel.svg"
          alt="popup close"
          class="popup__close"
          @click="closePopup"
        />
      </div>
      <div class="popup__body">
        <slot name="content"></slot>
      </div>
      <div class="popup__footer">
        <slot name="actions" class="popup__actions"></slot>
        <ca-button
          v-if="useCloseAction"
          :color="'dark'"
          class="ml-1"
          @click.native="closePopup"
          >Cancel</ca-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CaButton from "../buttons/CaButton";
export default {
  name: "CaPopup",
  components: { CaButton },
  props: {
    header: {
      type: String,
      required: true,
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    useCloseAction: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closePopup() {
      if (this.$refs.popup.classList.contains("active")) {
        this.$emit("close");
        this.$refs.popup.classList.remove("active");
      }
    },
    openPopup() {
      if (!this.$refs.popup.classList.contains("active")) {
        this.$emit("open");
        this.$refs.popup.classList.add("active");
      }
    },
  },
  watch: {
    toggle(val) {
      if (val === true) {
        this.openPopup();
      } else {
        this.closePopup();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  display: none;
  &.active {
    display: block;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000202;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );

  &__container {
    position: relative;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
    transition: all 1s linear;
  }
  &.active > &__container {
    width: 30%;
    min-height: 25rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #707070;
    width: 100%;

    & > img {
      margin-right: 1rem;
    }
  }

  &__header {
    font-size: 1.2rem;
    color: var(--cl-primary);
  }

  &__close {
    position: absolute;
    left: 93%;
    transform: translateX(-93%);
    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    color: #666666;
    font-size: 1.3rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__actions {
    display: flex;
    flex-direction: row;
  }
}
</style>
