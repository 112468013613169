<template>
  <th class="table__col table__col--h">

    <span class="table__col-title">{{ column.headerName }}</span>
    <span
      class="table__sort-toggle"
      v-if="column.sortable"
      @click="toggleEditor"
    >
      <img src="~@/assets/images/icons/polygon3.svg" alt="" />
    </span>

    <div
      class="table__col__editor editor"
      :class="{ opened: isOpened }"
      v-if="column.sortable"
      @focusout="closeEditor"
      tabindex="0"
    >
      <div class="editor__sort sorter">
        <div
          class="sorter__child sorter__child--asc"
          :class="{ active: sortActive === 'asc' }"
          @click="toggleAscending('asc')"
        >
          <span class="sorter__span sorter__alpha">
            <span class="sorter__alpha--top">A</span>
            <span class="sorter__alpha--bottom">Z</span>
          </span>
          <span class="sorter__span sorter__icon">&uarr;</span>
          <span class="sorter__span sorter__text"
          @click="$emit('sort', column.field, 'asc')"
            >Sort Smallest to Largest</span
          >
        </div>
        <div
          class="sorter__child sorter__child--dec"
          :class="{ active: sortActive === 'dec' }"
          @click="toggleAscending('dec')"
        >
          <span class="sorter__span sorter__alpha">
            <span class="sorter__alpha--top">A</span>
            <span class="sorter__alpha--bottom">Z</span>
          </span>
          <span class="sorter__span sorter__icon">&darr;</span>
          <span class="sorter__span sorter__text"
          @click="$emit('sort', column.field, 'dec')"
            >Sort Largest to Smallest</span
          >
        </div>
      </div>

      <div action="" class="editor__search searcher">
        <label for="searchInput" class="searcher__label"
          ><i class="fa fa-search"></i
        ></label>
        <input
          id="searchInput"
          type="text"
          placeholder="search"
          class="searcher__input"
          @input="$emit('search', $event.target.value, column.field)"
        />
      </div>
    </div>
  </th>
</template>

<script>
import { EventBus } from "../../../../main";
export default {
  name: "TableHead",
  data() {
    return {
      isOpened: false,
      sortActive: "asc",
    };
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortDir() {
      if (this.sortActive === "asc") {
        return "";
      } else {
        return "-";
      }
    },
  },
  methods: {
    toggleEditor() {
      this.isOpened = !this.isOpened;
    },
    closeEditor() {
      this.isOpened = false;
    },
    toggleAscending(value) {
      this.sortActive = value;
    },
    buildQuery() {
      /**
       * This method is supposed to help in building the
       * query that will be made to the API to fetch the filtered data.
       * I don't even know how this will work out yet.
       *
       * Every column in the table head must return an object containing 2 things:
       *
       * {
       *   indexName: "__the_field_attached_to_the_column__",
       *   howItShouldBeSorted: "+(or -)__the_field_attached_to_the_column__"
       * }
       */

      return {
        indexName: this.column.field,
        sortDir: this.sortDir,
      };
    },

    created() {},
  },
  watch: {
    sortActive() {
      const sortParams = this.buildQuery();
      EventBus.$emit("table-col-sort", sortParams);
    },
  },
};
</script>

<style scoped>
.editor {
  /*display: none;*/
  position: absolute;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1rem;
  min-width: 17rem;
  border-radius: 4px;
}
</style>
