var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ca-table-container",class:{ loading: _vm.fetchingData }},[(!_vm.pageIsLoading)?_c('table',{staticClass:"table"},[_c('thead',{staticClass:"table__head"},[_c('tr',{staticClass:"table__row"},_vm._l((_vm.columnDefs),function(column){return _c('table-head',{key:column.id,attrs:{"column":column},on:{"sort":_vm.sort,"search":_vm.search}})}),1)]),_c('tbody',{staticClass:"table__body"},_vm._l((_vm.dataToDisplay),function(row,index){return _c('tr',{key:index,staticClass:"table__row"},_vm._l((_vm.columnDefs),function(cl,cIndex){return _c('td',{key:cIndex,staticClass:"table__col table__col--d"},[(cl.hasCellRendererFramework)?_c('div',[_vm._t("cell-renderer",null,{"currentRowData":row})],2):_c('span',[_c('span',[(row[cl.field] !== null && row[cl.field] !== '')?_c('span',[(cl.type === 'date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("parseDate")(row[cl.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(row[cl.field])+" ")])]):_c('span',[(cl.hasOptionalDataFields)?_c('span',[(
                      _vm.getFirstDataValueNotEmpty(
                        cl.hasOptionalDataFields,
                        row
                      ) !== ''
                    )?_c('span',[_vm._v(" "+_vm._s(row[_vm.getFirstDataValueNotEmpty(cl.hasOptionalDataFields)])+" ")]):_c('span',[_vm._v(_vm._s(_vm.emptyTableInput))])]):_c('span',[(
                      cl.defaultFieldValue && cl.defaultFieldValue !== null
                    )?_c('span',[_vm._v(" "+_vm._s(cl.defaultFieldValue)+" ")]):_c('span',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.emptyTableInput))])])])])])])])}),0)}),0)]):_vm._e(),(!_vm.fetchingData && _vm.shouldPaginate)?_c('div',{staticClass:"table__footer"},[_c('div',{staticClass:"table__footer-ipp"},[_c('p',[_vm._v("Showing")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemsPerPage),expression:"itemsPerPage"}],attrs:{"name":"ipp","id":"ipp"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.itemsPerPage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"75"}},[_vm._v("75")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])]),_c('p',[_vm._v("items per page")])]),_c('div',{staticClass:"table__footer-pagination pagination"},[_c('vs-pagination',{attrs:{"square":"","length":_vm.tableLength,"color":_vm.paginationColor},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }