<template>
  <div>
    <page-header
      :title="'Users'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'Users'"
      :link-url="'/users'"
    >
      <ca-button :type="'ghost'" class="mr-1" @click.native="exportCSV">Export CSV</ca-button>
      <ca-button @click.native="createUser" >Create User </ca-button>
    </page-header>

    <full-page-content-container>
      <!-- User Delete Confirmation Popup   -->
      <ca-popup
        :header="'Delete User'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentUser !== null"
      >
        <template v-slot:content>
          Are you sure you want to delete the user?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deleteUser">Yes</ca-button>
        </template>
      </ca-popup>
      <!-- filter by all, verified and unverified -->
      <div class="filter">
        <button
          @click="filterBy('all')"
          :class="filterByActive == 'all' ? 'active' : ''"
          class="filter-item"
        >
          All
        </button>
        <button
          @click="filterBy('verified')"
          :class="filterByActive == 'verified' ? 'active' : ''"
          class="filter-item"
        >
          Verified
        </button>
        <button
          @click="filterBy('unverified')"
          :class="filterByActive == 'unverified' ? 'active' : ''"
          class="filter-item"
        >
          Unverified
        </button>
      </div>
      <grid-table
        v-if="rowData.length > 0"
        :column-defs="columnDefs"
        :row-data="rowData"
      >
        <template v-slot:cell-renderer="slotProps">
          <div>
            <span
              class="edit-icon"
              @click="editUser(slotProps.currentRowData.id)"
              ><img
                :src="require('@/assets/images/icons/edit.svg')"
                alt="edit icon"
            /></span>
            <span
              class="delete-icon"
              @click="confirmDelete(slotProps.currentRowData)"
            >
              <img
                :src="require('@/assets/images/icons/delete.svg')"
                alt="delete icon"
            /></span>
          </div>
        </template>
      </grid-table>
    </full-page-content-container>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import GridTable from "../../components/tables/GridTable";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import axios from "../../../axios";
import gridTableParentMixin from "../../../mixins/gridTableParentMixin";
import CaPopup from "../../components/popup/CaPopup";
export default {
  mixins: [gridTableParentMixin],
  name: "Users",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    CaPopup,
    FullPageContentContainer,
    GridTable,
    PageHeader,
    CaButton,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Names",
          field: "name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Email",
          field: "email",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Role",
          field: "role",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Company",
          field: "company_name",
          defaultFieldValue: "Admin",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Subscription",
          field: "subscripton",
          defaultFieldValue: "Free",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Created At",
          type: "date",
          field: "created_at",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Verified At",
          field: "email_verified_at",
          type: "date",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Last Login",
          field: "last_login",
          type: "date",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Sessions",
          field: "sessions",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/users`,
      openDeletePopup: false,
      currentUser: null,
      filterByActive: 'all',
    };
  },
  methods: {
     createUser(){
      this.$router.push({ name: 'create-user' });
    },
    editUser(userID) {
      this.$router.push({ name: "vu-edit-user", params: { id: userID } });
      console.log(userID);
    },
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          res.data.data.map(user=>{
            user.name = user.first_name? user.first_name +" "+user.last_name: user.name
          })
          this.rowData = []
          let data = res.data.data;


          if(this.filterByActive == 'verified'){
              console.log(this.filterByActive)
              data = res.data.data.filter(user=>{
                if(user.email_verified_at){
                  return user
                }
            });
            console.log(data)
          }
          if(this.filterByActive == 'unverified'){
              console.log(this.filterByActive)
              data= res.data.data.filter(user=>{
                if(!user.email_verified_at){
                  return user
                }
            });
          }
          this.rowData = data;
          console.log(this.rowData)
          loading.close();

          // this.closePageLoader();
        })
        .catch((err) => {
          loading.close();
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
        });
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        this.currentUser = popUpData;
      }
      this.openDeletePopup = true;
    },
    deleteUser() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/user/deactivate/${this.currentUser.id}`
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `User with id ${this.currentUser.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },

    filterBy(name) {
      this.filterByActive = name;
      console.log(name);
      this.rowData = [];
      this.getTableData(this.dataResourceUrl);
    },
    exportCSV(){
      let csvContent = "data:text/csv;charset=utf-8,";
      const date = new Date()
      const FullDate = date.getDate() +'-'+date.getMonth()+'-'+date.getFullYear()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
      const name = "caena-users-"+FullDate+".csv"
      let rowArray = Object.keys(this.rowData[0])
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      this.rowData.forEach(function(data) {
          let rowArray = Object.values(data)
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      });

     var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", name);
      document.body.appendChild(link); 

      link.click(); 
    }
  },
  created() {
    this.getTableData(this.dataResourceUrl);
  },
};
</script>

<style scoped>
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}

.content-container {
  padding: 3rem 1.5rem;
  background-color: #ffffff;
}
.filter{
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;

}
.filter-item{
  margin-right: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #1d817f;
  background: #fafafa;
  color: #1d817f;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300; 
}
.filter-item:hover{
  background: #e6f7f5;
}
.active{
  background: #e6f7f5;
}
</style>
