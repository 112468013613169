<template>
  <div class="ca-table-container" :class="{ loading: fetchingData }">
    <!--    <div class="loader" v-if=""></div>-->
    <table class="table" v-if="!pageIsLoading">
      <thead class="table__head">
        <tr class="table__row">
          <table-head
            v-for="column in columnDefs"
            :column="column"
            :key="column.id"
            @sort="sort"
            @search="search"
          ></table-head>
        </tr>
      </thead>
      <tbody class="table__body">
        <tr
          class="table__row"
          v-for="(row, index) in dataToDisplay"
          :key="index"
        >

          <!-- index table data  -->
          <!-- Each data cell loops through the columnDefinitions and use the current definition -->
          <!-- In the iteration to pick out the required needed data to show -->
          <td
            class="table__col table__col--d"
            v-for="(cl, cIndex) in columnDefs"
            :key="cIndex"
          >
            <!-- Some cell might have custom view to render. This is what handles that -->
            <!-- Cells that needs to render a custom view must have a property called -->
            <!-- hasCellRendererFramework set to true in their column definition -->
            <div v-if="cl.hasCellRendererFramework">
              <slot name="cell-renderer" v-bind:currentRowData="row"></slot>
            </div>
            <span v-else>
              <!-- See if data is to be fetched from multiple column -->
              <!-- For each table data cell, there's a default field to fetch value from -->
              <!-- In addition to the default field, there's an array of optional fields to -->
              <!-- look for. This is because, the default data field might contain empty data -->
              <!-- so we want to try to provide optional fields to look for this data. If the optional fields  -->
              <!-- are empty, then just display empty result instead  -->
              <span>
                <span v-if="row[cl.field] !== null && row[cl.field] !== ''">
                  <span v-if="cl.type === 'date'">
                    {{ row[cl.field] | parseDate }}
                  </span>
                  <span v-else>
                    {{ row[cl.field] }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="cl.hasOptionalDataFields">
                    <!-- Display the closest available data in the optionalFields-->
                    <span
                      v-if="
                        getFirstDataValueNotEmpty(
                          cl.hasOptionalDataFields,
                          row
                        ) !== ''
                      "
                    >
                      {{
                        row[getFirstDataValueNotEmpty(cl.hasOptionalDataFields)]
                      }}
                    </span>
                    <span v-else>{{ emptyTableInput }}</span>
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        cl.defaultFieldValue && cl.defaultFieldValue !== null
                      "
                    >
                      {{ cl.defaultFieldValue }}
                    </span>
                    <span class="text-center" v-else>
                      <span>{{ emptyTableInput }}</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table__footer" v-if="!fetchingData && shouldPaginate">
      <div class="table__footer-ipp">
        <p>Showing</p>
        <select name="ipp" id="ipp" v-model="itemsPerPage">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
        <p>items per page</p>
      </div>
      <div class="table__footer-pagination pagination">
        <vs-pagination
          v-model="currentPage"
          square
          :length="tableLength"
          :color="paginationColor"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main";
import TableHead from "./components/TableHead";
export default {
  name: "GridTable",
  components: { TableHead },
  props: {
    columnDefs: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Array,
      required: true,
    },
    components: [],
    fetchingData: {
      type: Boolean,
      default: false,
    },
    // totalItems: {
    //   type: Number,
    // },
    shouldPaginate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      paginationColor: "#1d817f",
      dataToDisplay: [],
      queryString: "",
      editorQueryElements: [],
      itemsPerPage: 25,
      currentPage: 1,
      sortedData: [],
    };
  },
  filters: {
    parseDate(date) {
      return new Date(date).toDateString();
    },
  },
  computed: {
    totalItems() {
      return this.rowData.length;
    },
    tableLength() {
      const tableLength = Math.ceil(this.totalItems / this.itemsPerPage);
      if (tableLength <= 0) {
        return 1;
      }
      return tableLength;
    },
  },

  methods: {
    getFirstDataValueNotEmpty(arrayOfDataField, rowData) {
      if (arrayOfDataField.length <= 0) return "";
      let notEmptyField = null;
      // arrayOfDataField.forEach((dataField) => {
      //   if (rowData[dataField] !== null && rowData[dataField] !== "") {
      //     notEmptyField = dataField;
      //   }
      // });
      for (let i = 0; i < arrayOfDataField.length; i++) {
        if (
          rowData[arrayOfDataField[i]] !== null &&
          rowData[arrayOfDataField[i]] !== ""
        ) {
          notEmptyField = arrayOfDataField[i];
          break;
        }
      }
      if (notEmptyField !== null) {
        return notEmptyField;
      }

      return "";
    },
    resolveDataToDisplay(currentPage = this.currentPage , data = this.rowData) {
      currentPage = parseInt(currentPage);
      const start = (currentPage - 1) * this.itemsPerPage;
      const stop = start + this.itemsPerPage;
      this.dataToDisplay = data.slice(start, stop);
    },

    updateEditorQueryElements(payload) {
      let existing = false;
      let existingEl = null;
      if (this.editorQueryElements.length > 0) {
        this.editorQueryElements.forEach((el) => {
          if (el.indexName === payload.indexName) {
            el.sortDir = payload.sortDir;
            existing = true;
            existingEl = el;
          }
        });
      }
      if (existing === false && existingEl === null) {
        this.editorQueryElements.push(payload);
      }
      this.buildQueryString();
    },

    buildQueryString() {
      this.queryString = `page=${this.currentPage}`;
      if (this.editorQueryElements.length > 0) {
        this.queryString += "&sort=";
        this.editorQueryElements.forEach((el) => {
          this.queryString += `${el.sortDir}${el.indexName},`;
        });
      }

      this.queryString = this.queryString.substring(
        0,
        this.queryString.length - 1
      );
      // Add itemsPerPage
      this.queryString += `&items_per_page=${this.itemsPerPage}`;
    },
    sort(indexName, sortType) {
      let row = []
      if(sortType === 'asc'){
        row = this.rowData.sort((a,b)=>(a[indexName] > b[indexName]) ? 1 : ((b[indexName] > a[indexName]) ? -1 : 0))
      }else{
        row = this.rowData.sort((a,b)=>(a[indexName] < b[indexName]) ? 1 : ((b[indexName] < a[indexName]) ? -1 : 0))
      }
    
    // console.log(row)
    this.resolveDataToDisplay(this.currentPage, row);
    this.sortedData = row;
    },

    search(value, indexName) {
      // console.log(value)
      let row = []
      row = this.rowData.filter(row => row[indexName].toLowerCase().includes(value.toLowerCase()))
      this.resolveDataToDisplay(this.currentPage, row);
      this.sortedData = row;
    }
    
  },
  watch: {
    currentPage(val) {
      this.buildQueryString();
      this.resolveDataToDisplay(val);
    },
    itemsPerPage() {
      this.buildQueryString();
      this.resolveDataToDisplay();
    },
    queryString(val) {
      EventBus.$emit("table-edited", val);
    },
  },
  created() {
    this.resolveDataToDisplay(this.currentPage);
    this.sort('id', 'desc');
    EventBus.$on("table-col-sort", (payload) => {
      this.updateEditorQueryElements(payload);
    });
  },
};
</script>

<style lang="scss" scoped>
/*.vs-pagination__active {*/
/*  color:  !important;*/
/*}*/
@import "~@/assets/scss/base/variables";
.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--cl-primary); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  left: 50%;
  top: 30%;
  transform: translateY(-50);
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ca-table-container.loading {
  position: relative;
  height: 20rem !important;
}
</style>
