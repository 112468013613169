import { EventBus } from "../main";

// Any component that uses this mixin must
// have a method called getTableData
const gridTableParentMixin = {
  data() {
    return {
      // These are data values for Components that have tables in them
      defaultQueryString: "page=1&items_per_page=25",
      dataResourceUrl: "",
    };
  },
  beforeRouteLeave($from, $to, $next) {
    EventBus.$off();
    $next();
  },
  mounted() {
    EventBus.$on("table-edited", (queryString) => {
      this.getTableData(`${this.dataResourceUrl}?${queryString}`);
      console.log("Below is going to be the updated url to make the request");
      console.log(`${this.dataResourceUrl}?${queryString}`);
    });
  },
};

export default gridTableParentMixin;
